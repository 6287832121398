import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"value":_vm.visible,"persistent":"","max-width":"90%","width":600}},[_c(VCard,{staticClass:"mx-auto",attrs:{"outlined":""}},[_c(VCardText,{staticClass:"pa-5 subtitle-1",attrs:{"id":"userAlertMessage"}},[(!_vm.showHtml)?_c('span',[_vm._v(_vm._s(_vm.message))]):_c('div',{domProps:{"innerHTML":_vm._s(_vm.parsedMessage)},on:{"click":function($event){return _vm.followLink(_vm.parsedLink)}}})]),_c(VDivider),_c(VFooter,{staticClass:"pa-1",attrs:{"padless":""}},[_c(VSpacer),(_vm.isConfirm)?_c(VBtn,{staticClass:"mr-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.cancelA()}}},[_vm._v(" No ")]):_vm._e(),_c(VBtn,{attrs:{"color":"success"},on:{"click":function($event){return _vm.confirmA()}}},[_vm._v(" "+_vm._s(_vm.isConfirm ? "Yes" : "Ok")+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }