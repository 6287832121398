<template>
  <div class="test">
    <iframe
      v-if="templatename"
      :src="$store.getters.server + templatename"
      width="100%"
      :style="`height: ${windowHeight};`"
    >
    </iframe>
    <v-progress-linear
      v-else
      app
      indeterminate
      color="#b83336"
    ></v-progress-linear>
  </div>
</template>
<script>
import downloadFileFromBase64String from '../../mixins/downloadFileFromBase64String.js';

export default {
  name: 'CentersTemplate',
  mixins: [downloadFileFromBase64String],
  props: {
    center: {
      type: String,
      default: '',
    },
    section: {
      type: String,
      default: '',
    },
    tab: {
      type: String,
      default: '',
    },
    kind: {
      type: String,
      default: '',
    },
    queries: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      templatename: '',
      windowHeight: '',
      disabledTime: false,
      location: {},
      watchLocation: null,
      event: { source: null, origin: null },
      file: null,
    };
  },
  computed: {
    urlparams() {
      return {
        center: this.center,
        section: this.section,
        tab: this.tab,
        kind: this.kind,
        queries: this.queries,
      };
    },
  },
  watch: {
    urlparams: {
      handler() {
        if (this.urlparams
          && this.urlparams.center
          && this.urlparams.section
          && this.urlparams.tab
          && this.urlparams.kind) {
          this.createUrlFromParams();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // Native interface frame
    const appBarHeader = document.getElementById('appBarHeader');
    const navDrawer = document.getElementById('navDrawer');
    const height = appBarHeader ? appBarHeader.offsetHeight + 7 : 7;
    const mainContainHeight = navDrawer ? navDrawer.offsetHeight : 0;
    this.windowHeight = `${mainContainHeight - height}px`;
    this.setupDownloadListener();
    window.addEventListener('message', this.native);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleDownload, false);
    window.removeEventListener('message', this.native, false);
    if (this.watchLocation !== null) {
      navigator.geolocation.clearWatch(this.watchLocation);
      this.watchLocation = null;
    }
  },
  methods: {
    createUrlFromParams() {
      const {
        center, section, tab, kind, queries,
      } = this.urlparams;
      const queryStr = Object.keys(queries).map((key) => {
        return `&${key}=${queries[key]}`;
      }).join('');
      // eslint-disable-next-line max-len
      const url = `controller.php?center=${center}&section=${section}&tab=${tab}&kind=${kind}&dataType=html${queryStr}`;
      this.templatename = url;
      this.checkForLogout();
    },
    setupDownloadListener() {
      window.removeEventListener('message', this.handleDownload, false);
      window.addEventListener('message', this.handleDownload, false);
    },
    handleDownload(event) {
      if (event.origin.includes('rinc.us')) {
        if (this.disabledTime) {
          return;
        }
        if (event.data.name && event.data.fileType) {
          this.disabledTime = true;
          // eslint-disable-next-line vue/no-undef-properties
          this.download(event.data.name, event.data.theData, event.data.fileType);
        } else if (event.data.url) {
          this.disabledTime = true;
          try {
            cordova.InAppBrowser.open(event.data.url, '_system');
          } catch (e) {
            // eslint-disable-next-line no-alert
            alert(e);
          }
        } else {
          return;
        }
        setTimeout(() => {
          this.disabledTime = false;
        }, 2000);
      }
    },
    checkForLogout() {
      // Runs a very light call, if it fails, user will get logged out
      this.$store.dispatch('getUser');
    },

    /**
    * Native interface methods from centers
    */
    native(event) {
      const { data } = event;
      this.event = event;
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      let agent;
      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        agent = 'Windows Phone';
      }

      if (/android/i.test(userAgent)) {
        agent = 'android';
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        agent = 'ios';
      }

      if (!agent) {
        agent = 'browser';
      }

      return ((typeof this[`${agent}_${data}`] !== 'undefined') && this[`${agent}_${data}`]()) || `Unknown method ${data}`;
    },
    android_camera() {
      return navigator.camera.getPicture((succes) => {
        this.file = succes;
        this.event.source.postMessage(this.file, this.event.origin);
      }, () => {
        // eslint-disable-next-line no-console
        console.log('Console Log photo error:', 'error retriving photo');
      }, {
        // Some common settings are 20, 50, and 100
        quality: 50,
        // eslint-disable-next-line no-undef
        destinationType: Camera.DestinationType.DATA_URL,
        // In this app, dynamically set the picture source, Camera or photo gallery
        // eslint-disable-next-line no-undef
        sourceType: Camera.PictureSourceType.CAMERA,
        // eslint-disable-next-line no-undef
        encodingType: Camera.EncodingType.JPEG,

        correctOrientation: true,
      });
    },
    ios_camera() {
      return this.android_camera();
    },
    browser_camera() {
      // Accessing the user camera and video.
      const mediaDevices = navigator.mediaDevices;
      mediaDevices
        .getUserMedia({
          video: true,
          audio: true,
        })
        .then((stream) => {
          // Changing the source of video to current stream.
          // eslint-disable-next-line no-console, no-alert
          console.log('Console Log stream:', stream);
        })
        .catch(alert);
    },
    // android_device_location() {
    //   this.watchLocation = navigator.geolocation.watchPosition(this.coords, this.coords, { enableHighAccuracy: true });
    // },
    // ios_device_location() {
    //   return this.android_device_location();
    // },
    // browser_device_location() {
    //   return this.android_device_location();
    // },
    // coords(data) {
    //   const { coords } = data;
    //   if (coords) {
    //     this.location = {
    //       accuracy: coords.accuracy,
    //       altitude: coords.altitude,
    //       altitudeAccuracy: coords.altitudeAccuracy,
    //       heading: coords.heading,
    //       latitude: coords.latitude,
    //       longitude: coords.longitude,
    //       speed: coords.speed,
    //     };
    //     this.event.source.postMessage(this.location, this.event.origin);
    //     return this.coords;
    //   }
    //   return data;
    // },
    requestFile(path) {
      window.resolveLocalFileSystemURL(
        path,
        (dir) => {
          // eslint-disable-next-line no-alert
          alert(JSON.stringify(dir));
        },
        () => {
          // eslint-disable-next-line no-alert
          alert('Unable to download');
        },
      );
    },
  },
};
</script>
<style scoped>
.test {
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
iframe {
  border: none
}
</style>
