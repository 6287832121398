<template>
  <div>
    <v-card
      flat
    >
      <v-card-title class="mb-3 pa-0">
        <v-toolbar
          width="100%"
          height="64px"
          color="blue"
          elevation="3"
        >
          <v-toolbar-title> Fuel Entry </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            x-large
            icon
            @click="$emit('close')"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="scrollable-content">
        <v-form
          ref="form"
          v-model="isValid"
          lazy-validation
        >
          <v-row
            class="pa-0"
            justify="center"
          >
            <v-col
              cols="12"
              md="6"
              class="pa-1 pt-3 px-4"
            >
              <v-autocomplete
                v-model="item.eq_num"
                :disabled="!!recordClickedOn.id"
                :items="equipmentList"
                :rules="rules.eq_num"
                :class="`${deviceBasedClasses}`"
                required
                attach
                prepend-icon="mdi-dump-truck"
                label="Select Equipment"
                clearable
                inputmode="numeric"
                @change="handleChangeEqNum"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              class="pa-1 px-4"
            >
              <div
                v-if="item.eq_num"
                no-gutters
                class="mb-4"
              >
                <span class="black--text">Last 5 Records:</span>
                <v-divider style="background-color: red">
                </v-divider>
                <v-divider style="background-color: red">
                </v-divider>
                <v-data-table
                  :headers="headers"
                  :items="singleEquipmentRecords"
                  :mobile-breakpoint="0"
                  fixed-header
                  hide-default-footer
                  dense
                >
                  <template
                    #item="row"
                  >
                    <tr
                      :style="`${row.item.offline ? 'background-color: purple; color: white' : 'background-color: white'}`"
                    >
                      <td style="text-align: center">
                        {{ row.item.date }}
                      </td>
                      <td style="text-align: center">
                        {{ row.item.primary_unit_measure === 0
                          ? `${row.item.current_mile } Miles`: `${row.item.current_hour } Hours` }}
                      </td>
                      <td style="text-align: center">
                        {{ row.item.gallons_added }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>

                <v-divider style="background-color: red">
                </v-divider>
                <v-divider style="background-color: red">
                </v-divider>
                <v-row
                  class="mt-1 pa-0"
                  justify="space-around"
                >
                  <v-col
                    v-if="currentUnitOfMeasurement === '0'"
                    class="pa-1"
                    cols="12"
                    md="4"
                  >
                    <b class="black--text">
                      {{ `Last Recorded Hours: ${currentHours}` }}
                    </b>
                  </v-col>
                  <v-col
                    v-else
                    class="pa-1"
                    cols="12"
                    md="4"
                  >
                    <b>
                      {{ `Last Recorded Miles: ${currentMiles}` }}
                    </b>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="pa-1 px-4"
            >
              <v-autocomplete
                v-model="item.job"
                :items="locations"
                :rules="rules.location"
                :class="`${deviceBasedClasses}`"
                required
                item-text="description"
                item-value="job_num"
                prepend-icon="mdi-google-maps"
                clearable
                return-object
                label="Select Job"
                @change="handleChangeJobNum"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="pa-1 px-4"
            >
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    :value="item.date"
                    :rules="rules.date"
                    :class="`${deviceBasedClasses}`"
                    required
                    prepend-icon="mdi-calendar"
                    readonly
                    label="Date"
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                  v-model="item.date"
                  locale="en-in"
                  no-title
                  style="width:100%"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="pa-1 px-4"
            >
              <v-text-field
                v-model="item.current_hour"
                :rules="rules.current_hour"
                :hint="item.eq_num ? `Last Recorded: ${currentHours}` : ''"
                :class="`${deviceBasedClasses}`"
                :required="item.eq_num && currentUnitOfMeasurement === '0'"
                persistent-hint
                prepend-icon="mdi-clock"
                label="Current Hours"
                clearable
                type="number"
                inputmode="decimal"
                pattern="[0-9.]*"
                @paste="handlePaste"
                @keypress="removeNonNumericalValues"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="pa-1 px-4"
            >
              <v-text-field
                v-model="item.current_mile"
                :rules="rules.current_mile"
                :hint="item.eq_num ? `Last Recorded: ${currentMiles}` : ''"
                :class="`${deviceBasedClasses}`"
                :required="item.eq_num && currentUnitOfMeasurement === '1'"
                persistent-hint
                prepend-icon="mdi-road"
                label="Current Miles"
                clearable
                type="number"
                inputmode="decimal"
                pattern="[0-9.]*"
                @paste="handlePaste"
                @keypress="removeNonNumericalValues"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="pa-1 px-4"
            >
              <v-text-field
                v-model="item.gallon"
                :rules="rules.gallon"
                :class="`${deviceBasedClasses}`"
                persistent-hint
                prepend-icon="mdi-gas-station"
                label="Gallons"
                required
                clearable
                type="number"
                inputmode="decimal"
                pattern="[0-9.]*"
                @paste="handlePaste"
                @keypress="removeNonNumericalValues"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="pa-1 px-4"
            >
              <v-text-field
                v-model="item.note"
                :class="`${deviceBasedClasses}`"
                prepend-icon="mdi-lead-pencil"
                label="Notes"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <!-- CANCEL AND SAVE BUTTONS -->
      <v-card-actions class="top-shadow">
        <v-spacer></v-spacer>
        <v-btn
          x-large
          color="warning"
          class="ma-2 pr-3"
          @click="handleClickCancel"
        >
          <v-icon left>
            mdi-close
          </v-icon>Cancel
        </v-btn>

        <v-btn
          id="reviewButton"
          :disabled="!hasChanges || recordClickedOn.submitted"
          x-large
          color="success"
          class="ma-2 pr-3"
          @click="handleClickSave"
        >
          <v-icon left>
            mdi-content-save
          </v-icon>
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      width="90vw"
    >
      <v-card>
        <v-card-title style="margin: auto">
          Validation Error
        </v-card-title>
        <v-card-text class="error-text">
          {{ errorText }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="ma-2"
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            id="reviewButton"
            color="success"
            class="ma-2"
            @click="handleClickSaveAnyways"
          >
            <v-icon left>
              mdi-cloud-upload-outline
            </v-icon>
            Save anyways?
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable sonarjs/cognitive-complexity */
import moment from 'moment/src/moment';
import fetchDataNewAPI from '../../mixins/fetchDataNewAPI.js';

export default {
  name: 'FuelerEntry',
  mixins: [fetchDataNewAPI],
  props: {
    loggedInUser: {
      type: String,
      required: true,
    },
    recordClickedOn: {
      type: Object,
      required: true,
    },
    locations: {
      type: Array,
      required: true,
    },
    equipment: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      isValid: false,
      storageKey: 'fueler',
      item: {
        note: '',
        eq_num: '',
        id: null,
        job_num: '',
        job: '',
        location_id: null,
        date: null,
        current_hour: null,
        current_mile: null,
        gallon: null,
        emp_num: null,
        fuel_type: null,
        fuel_use: null,
        fuel_sale: null,
        unit_of_measure: '0',
        index: null,
      },
      dateMenu: false,
      dialog: false,
      equipmentList: [],
      entry: {
        index: 0,
      },
      errorText: '',
      singleEquipmentRecords: [],
      headers: [
        { text: 'Date', value: 'date', align: 'center' },
        { text: 'Current Hours/Miles', value: 'current_hour', align: 'center' },
        { text: 'Gallons', value: 'gallons_added', align: 'center' },
      ],
      rules: {
        eq_num: [
          (v) => {
            return !!v || 'Required';
          },
        ],
        location: [
          (v) => {
            return !!v || 'Required';
          },
        ],
        date: [
          (v) => {
            return !!v || 'Required';
          },
        ],
        current_hour: [
          (v) => {
            return !Number.isNaN(v) || 'Must be a number';
          }, (v) => {
            return !v || Math.floor(v).toString().length <= 8 || 'Max 8 Whole Numbers';
          },
        ],
        current_mile: [
          (v) => {
            return !Number.isNaN(v) || 'Must be a number';
          }, (v) => {
            return !v || Math.floor(v).toString().length <= 8 || 'Max 8 Whole Numbers';
          },
        ],
        gallon: [
          (v) => {
            return !!v || 'Required';
          },
          (v) => {
            return !Number.isNaN(v) || 'Must be a number';
          }, (v) => {
            return !v || Math.floor(v).toString().length <= 3 || 'Max 3 Whole Numbers';
          },
        ],
      },
      noServiceAlertExclusions: [],
    };
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.item) !== JSON.stringify(this.recordClickedOn);
    },
    isNotASavedEntry() {
      return !this.recordClickedOn.id || this.recordClickedOn.submitted;
    },
    currentUnitOfMeasurement() {
      // 0 = hours, 1 = miles
      if (this.selectedEquipment && this.selectedEquipment.unit_of_measure) {
        return this.selectedEquipment.unit_of_measure;
      }
      return '0';
    },
    selectedEquipment() {
      const selected = this.equipment.find((e) => {
        return e.eq_num === this.item.eq_num;
      });
      const records = this.singleEquipmentRecords;
      const uom = records.length > 0 ? records[0].unit_of_measure : '0';
      return { ...selected, unit_of_measure: uom };
    },
    server() {
      return `${this.$store.getters.server}controller.php`;
    },
    deviceBasedClasses() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return 'large-font extra-padding';
      }
      return '';
    },
    currentMiles() {
      if (this.singleEquipmentRecords.length > 0) {
        return this.singleEquipmentRecords[0].current_mile;
      }
      return 'Unknown';
    },
    currentHours() {
      if (this.singleEquipmentRecords.length > 0) {
        return this.singleEquipmentRecords[0].current_hour;
      }
      return 'Unknown';
    },
  },

  watch: {
    async recordClickedOn() {
      await this.init();
    },
  },

  async created() {
    await this.init();
  },

  methods: {
    ////////////////////////////
    // INIT AND REFRESH METHODS
    ////////////////////////////
    async init() {
      this.item = { ...this.recordClickedOn };

      // If the record is new, set the last job to the last job used
      // and set the date to today
      if (Object.keys(this.recordClickedOn).length === 0) {
        const lastJob = localStorage.getItem(`${this.storageKey}:lastJob`);
        this.item.job = lastJob ? JSON.parse(lastJob) : null;
        this.item.date = moment().format('YYYY-MM-DD');
      } else {
        // Otherwise, set the job to the job of the record
        this.item.job = this.locations.find((f) => {
          return Number(f.job_num) === Number(this.recordClickedOn.job)
          || Number(f.job_num) === Number(this.recordClickedOn.job_num);
        }) || null;
      }

      this.handleChangeJobNum(this.item?.job);

      // Get the records for the selected equipment
      this.singleEquipmentRecords = await this.getSingleEquipmentRecords(this.recordClickedOn?.eq_num);

      // Get the alert exclusions
      this.noServiceAlertExclusions = await this.returnAlertExclusionsPerType();

      // Update the validation rules based on the selected equipment
      this.$nextTick(() => {
        this.updateRules();
      });
    },

    ////////////////////////////
    // HANDLER FUNCTIONS
    ////////////////////////////
    handleChangeJobNum(val) {
      const jobEquipment = this.$store.state.shared.jobEquipment;
      const eqArr = this.$store.state.shared.equipment
        .map((eq) => {
          return { text: `${eq.eq_num} ${eq.description}`, value: eq.eq_num };
        });
      const newJob = val;

      if (newJob) {
        localStorage.setItem(`${this.storageKey}:lastJob`, JSON.stringify(newJob));
      }

      if (newJob && newJob.job_num && jobEquipment[Number(newJob.job_num)]) {
        const jobEqArr = jobEquipment[Number(newJob.job_num)]
          .map((eq) => {
            return { text: eq.description, value: eq.eq_num };
          });
        this.equipmentList = [
          { header: 'Job Equipment' },
          ...jobEqArr,
          { divider: true },
          { header: 'All Equipment' },
          ...eqArr,
        ];
      } else {
        this.equipmentList = [
          { header: 'All Equipment' },
          ...eqArr,
        ];
      }
    },

    async handleChangeEqNum(val) {
      this.item.eq_num = val;
      await this.updateEquipmentRecords();
      await this.updateRules();
      await this.updateLastLocation();
      this.blurAllInputs();
    },

    // Removes all non-numeric characters from pasted content
    handlePaste(event) {
      const pastedContent = event.clipboardData.getData('text/plain');
      const cleanedContent = pastedContent.replace(/[^0-9.]/g, '');
      event.preventDefault();
      document.execCommand('insertText', false, cleanedContent);
    },

    // Removes the current entry from the saved records
    handleClickRemove() {
      const entry = this.item;
      this.$emit('remove', JSON.parse(JSON.stringify(entry)));
    },

    // Closes the screen without saving or removing the entry
    handleClickCancel() {
      this.$emit('close');
    },

    // Check if the equipment has services, if it does not, alert the user
    // (First) check if the alerts are muted for the specific equipment types
    // returns true if everything looks good
    async checkServiceStatus(entry) {
      const equipmentType = await this.getEquipmentType(entry.eq_num);
      const alertExcludedForType = this.noServiceAlertExclusions.find((e) => {
        return Number(e.eqp_typ_num) === Number(equipmentType.type);
      }).exclude_alerts === '1';

      const services = await this.returnServicesForEquipment(entry.eq_num);
      return !(!services.error && services.empty && !alertExcludedForType);
    },

    // Saves the current entry
    async handleClickSave() {
      const isOnline = this.$store.getters.online;
      const isValid = this.$refs.form.validate();
      const entry = this.formatEntry(this.item);

      const services = await this.checkServiceStatus(entry);
      if (!services) {
        this.errorText = 'The selected equipment does not have any services. Please contact lube department.';
        this.dialog = true;
        return;
      }

      if (isOnline) {
        if (!isValid) {
          this.errorText = 'This form contains errors.\nWould you like to save anyways?';
          this.dialog = true;
        } else {
          const res = await this.getOnlineValidation(entry);
          if (res.accurate === 0) {
            this.errorText = res.reading_message;
            this.dialog = true;
          } else {
            this.saveEntry(entry);
          }
        }
      } else {
        this.saveEntry(entry);
      }
    },

    // Saves the current entry regardless of validation errors
    handleClickSaveAnyways() {
      const entry = this.formatEntry(this.item);
      this.dialog = false;
      this.saveEntry(entry);
    },

    ////////////////////////////
    // LOGIC / HELPER FUNCTIONS
    ////////////////////////////
    // Removes the virtual keyboard from the screen
    blurAllInputs() {
      document.activeElement.blur();
    },

    // Updates the rules based on the selected equipment
    updateRules() {
      // Remove the optional required rule from both hours and miles
      if (this.rules.current_hour.length === 3) {
        this.rules.current_hour.pop();
      }
      if (this.rules.current_mile.length === 3) {
        this.rules.current_mile.pop();
      }

      // Add the "required" rule to miles or hours based on the selected equipment
      if (this.item.eq_num) {
        if (this.currentUnitOfMeasurement === '0') {
          this.rules.current_hour.push((v) => {
            return !!v || 'Required';
          });
        } else {
          this.rules.current_mile.push((v) => {
            return !!v || 'Required';
          });
        }
      }
      this.$refs.form.validate();
    },

    // Get the records for the selected equipment
    async updateEquipmentRecords() {
      this.singleEquipmentRecords = await this.getSingleEquipmentRecords(this.item.eq_num);
    },

    // Updates the last location based on the selected equipment
    updateLastLocation() {
      const temp = this.locations.find((f) => {
        return Number(f.job_num) === Number(this.selectedEquipment.job_num);
      });

      if (temp) {
        this.item.job = temp;
      }
    },

    //only works if using @keypress
    removeNonNumericalValues(event) {
      if (!parseFloat(event.key) && event.key !== '0' && event.key !== '.') {
        event.preventDefault();
      }
    },

    // Saves the entry
    saveEntry(entry) {
      this.$emit('save', JSON.parse(JSON.stringify(entry)));
    },

    // Formats the entry to be saved. Required before submitting an entry.
    formatEntry(item) {
      const isValid = this.$refs.form.validate();
      const isOnline = this.$store.getters.online;
      const job = item.job;

      return {
        id: item.id || null,
        job_num: job ? job.job_num : item?.job_num,
        location_id: job ? job.location_id : item?.location_id,
        date: item.date,
        current_hour: item.current_hour || null,
        current_mile: item.current_mile || null,
        gallon: item.gallon,
        emp_num: this.loggedInUser,
        note: item.note || null,
        fuel_type: item.fuel_type || '0',
        fuel_use: item.fuel_use || '0',
        fuel_sale: item.fuel_sale || '0',
        unit_of_measure: this.currentUnitOfMeasurement,
        eq_num: item?.eq_num || this.recordClickedOn.eq_num,
        index: String(item.index) || null,
        offline: !isOnline,
        valid: isValid,
      };
    },

    ////////////////////////////
    // API FUNCTIONS
    ////////////////////////////
    // Checks for any discrepancies in the equipment information
    async getOnlineValidation(entry) {
      const cstk = ['equipment', 'action', 'single', 'checkIfMeterReadingIsAccurate'];
      const hourOrMiles = this.currentUnitOfMeasurement === '0' ? entry.current_hour : entry.current_mile;
      const userData = {
        eq_num: entry.eq_num,
        unit_of_measure: this.currentUnitOfMeasurement,
        current_hour_or_mile_reading: hourOrMiles,
        date_of_reading: entry.date,
      };
      const response = await this.fetchDataNew(cstk, userData, {
        alternate_url: this.server,
      });
      if (response.unauthenticated) {
        this.$store.dispatch('logout');
      }
      if (!response.error && !response.empty) {
        return response.response.data;
      }
      if (response.empty) {
        return [];
      }
      return [];
    },

    // Gets the last 5 records for the selected equipment
    async getSingleEquipmentRecords(eqNum) {
      const isOnline = this.$store.getters.online;
      if (!isOnline || !eqNum) {
        return [];
      }
      const cstk = ['equipment', 'return', 'multiple', 'fuelRecordsForGivenEquipment'];
      const userData = {
        eq_num: eqNum,
        numberOfRecordsToReturn: 5,
      };
      const response = await this.fetchDataNew(cstk, userData, {
        alternate_url: this.server,
      });
      if (response.unauthenticated) {
        this.$store.dispatch('logout');
      }
      if (!response.error && !response.empty) {
        return response.response.data;
      }
      if (response.empty) {
        return [];
      }
      return [];
    },

    // Gets the type of equipment given an eq_num
    async getEquipmentType(eqNum) {
      const cstk = ['equipment', 'return', 'single', 'equipmentType'];
      const userData = { eq_num: eqNum };
      const response = await this.fetchDataNew(cstk, userData, {
        alternate_url: this.server,
      });
      if (!response.error && !response.empty) {
        return response.response.data;
      }
      return false;
    },

    // Gets alert exclusions per type
    async returnAlertExclusionsPerType() {
      // alert exclusions are in the serviceShownTypes table
      const cstk = ['equipment', 'return', 'all', 'serviceShownTypes'];
      const response = await this.fetchDataNew(cstk, {
        alternate_url: this.server,
      });
      if (!response.error && !response.empty) {
        return response.response.data;
      }
      return false;
    },

    // Get the list of services for a peice of equipment
    async returnServicesForEquipment(eqNum) {
      const cstk = ['equipment', 'return', 'all', 'latestServicesForGivenEquipment'];
      const userData = { eq_num: eqNum };
      return this.fetchDataNew(cstk, userData, {
        alternate_url: this.server,
      });
    },
  },
};
</script>
<style>
.theme--dark.v-subheader {
  color: #ef9f0ef0;
}

.large-font .v-label {
  font-size: 1.1em !important;
}

.large-font input {
  font-size: 1.15em !important;
}

.extra-padding {
  padding-bottom: 8px !important;
}

.error-text {
  font-size: 1.1em;
  white-space: pre;
  padding-bottom: 4px !important;
}

.scrollable-content {
  height: calc(100vh - 160px);
  overflow-y: auto;
}

.card-actions {
  height: 96px;
}

.top-shadow {
  width: 100%;
  box-shadow: 0px -5px 5px -5px rgba(0, 0, 0, 0.2);
}

.bot-shadow {
  box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.2) !important;
}

/* Remove arrows in number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  appearance: textfield;
}
</style>
